.card-container {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 1em;
}

.gapOnSmallScreen {
  @media (max-width: 1040px) {
    row-gap: 1em !important;
  }
}

.rowOnDesktop {
  @media (min-width: 1040px) {
    flex-direction: row !important;
    align-items: center !important;
    column-gap: 1em !important;
    .center {
      justify-content: center !important;
    }
  }
}

.variantsSectionContainer {
  position: relative;
  min-width: 70%;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.productConfigLayout {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  column-gap: 2em;
  @media (max-width: 776px) {
    column-gap: 0;
  }
}

.property_name {
  height: 25px;
}

.property_value {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

select,
option {
  border-radius: 1em;
  height: 32px;
  padding: 0 5px;
}

.cont-add-new-variant .cont-variant {
  max-width: 200px;
}

.cont-add-new-variant .cont-variant .btn {
  cursor: pointer;
  padding: 0 0 15px 0;
}

.cont-add-new-variant .cont-name, .cont-value {
  width: 49%;
}

.delete-row {
  background-color: #fff2f0;
  .anticon {
    color: #787878;
  }
}
.new-row {
  background-color: #f6ffed;
  .anticon {
    color: #787878;
  }
}
.update-row {
  background-color: #e6f4ff;
  .anticon {
    color: #787878;
  }
}
.default-row {
  background-color: transparent;
  .anticon {
    color: #787878;
  }
}
