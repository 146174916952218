@import '../../styles/styles';

.card-modal {
  display: flex;
  background-color: white;
  border-radius: 12px;
  flex-direction: column;
  width: 130px;
  height: 150px;
  margin: 1em 1em;
}
.card-top {
  display: flex;
  justify-content: space-between;
  margin-top: 3px;
}

.card-action {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.card-bottom {
  text-align: center;
  padding: 0.5rem 0.8rem;
  &.image-contain {
    object-fit: contain;
    width: 100px;
    height: 70px;
  }
}

.grid-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
