.take-off-page {
  padding: 1.3rem 1rem;
}

.take-off-page-title {
  color: #1a002d;
  font-size: 1.125rem;
  font-weight: 600;
}
.take-off-page-content {
  margin-top: 1rem;
  padding: 1.25rem 1.13rem;
  border-radius: 1.25rem;
  background: #f6f6f4;
}
.take-off-page-banner {
  max-width: 100%;
  height: 9.1875rem;
  border-radius: 0.625rem;
  display: flex;
  justify-content: center;
  align-items: end;
  background: black;
  padding: 1rem 0.5rem;
  position: relative;
}
.take-off-page-banner .bannerImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.take-off-page-subtitle {
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
  position: relative;
  z-index: 1;
  text-align: center;
}
.take-off-plan-description {
  margin-top: 1rem;
}
.take-off-plan-description p {
  color: #3f3f3f;
  font-size: 1rem;
  font-weight: 400;
}

.take-off-plan-features {
  margin-top: 1rem;
}

.take-off-feature-card {
  position: relative;
  border-radius: 0.3125rem;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0.63rem;
  padding: 1.9rem 0rem;
}
.stage-indicator {
  width: 4.9375rem;
  height: 2rem;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #fff;
  font-family: Lato;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 1rem;
  border-radius: 0rem 0.375rem 0.375rem 0rem;
  box-shadow: 6px 0px 15px -5px rgba(0, 0, 0, 0.25);
}
.stage-img-container {
  align-self: center;
}

.stage-img-container img {
  height: 4.43rem;
  width: 4.43rem;
}
.stage-text {
  align-self: center;
  width: 8rem;
  text-align: center;
  margin-top: 0.81rem;
  font-size: 0.875rem;
  color: #010101;
}
.take-off-plan-button-container {
  margin-top: 1.4rem;
  text-align: center;
}
.take-off-plan-button {
  width: 19rem !important;
  height: 2.625rem !important;
  font-size: 1.125rem !important;
  font-weight: 500 !important;
  background: #06c2ac !important;
  color: #fff !important;
  border: none !important;
  cursor: pointer !important;
}
@media screen and (max-width: 425px) {
  .take-off-plan-button {
    width: 100%;
  }
}
@media screen and (min-width: 1100px) {
  .take-off-page {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .take-off-page-title {
    width: 54rem;
    text-align: left;
  }
  .take-off-page-content {
    margin-top: 1.13rem;
    padding: 3rem 7rem;
    max-width: 62rem;
  }

  .take-off-page-banner {
    height: 10.3rem;
    justify-content: start;
    align-items: end;
    padding: 1.62rem 2.25rem;
  }
  .take-off-page-subtitle {
    font-size: 1.56rem;
    margin: 0;
  }
  .take-off-plan-description {
    margin-top: 3.38rem;
  }

  .take-off-plan-features {
    margin-top: 3.38rem;
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;
  }

  .take-off-feature-card {
    width: 100%;
    padding: 4.19rem 0rem 1.9rem 0rem;
  }
  .take-off-plan-button-container {
    margin-top: 2.75rem;
    text-align: center;
  }
  .take-off-plan-button {
    width: 19rem !important;
    height: 2.625rem !important;
    font-size: 1.125rem !important;
    font-weight: 500 !important;
  }
}
