@import '../../styles/styles';

.switches__row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.variant-property-form {
  margin-top: 1rem;
}
.create-variant-button-container {
  width: 100%;
  text-align: right;
}
.switch__item {
  width: 100%;
  max-width: 200px;
  min-width: 100px;
  display: flex;
  align-items: center;
  margin-right: 20px;
  padding-right: 10px;
}

.products__grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
  position: relative;
  @media (max-width: $mobile-l) {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }
}
