@import '../../styles/styles';

.layout {
  display: flex;
  min-height: 100vh;
  max-height: 100vh;
  max-height: --webkit-fill-available;
}

.viewsContainer {
  padding: 20px 15px;
  background-color: white;
  flex-grow: 1;
  transition: width 0.4s;
  max-width: 100%;
  width: 100%;
  overflow-x: hidden;
  @media (max-width: $tablet) {
    max-width: 100%;
    padding: 10px;
  }
}
