.row {
  display: flex;
  padding: 20px 15px;
  flex-wrap: wrap;
  align-items: center;
  &.center {
    justify-content: center;
  }
  &.reverse {
    flex-direction: row-reverse;
  }
  &.no-padding {
    padding: 0;
  }
  &.gap {
    column-gap: 1em;
    row-gap: 1em;
  }
  &.left {
    justify-content: left;
  }
  &.right {
    justify-content: right;
  }
  &.space-between {
    justify-content: space-between;
  }
  &.space-around {
    justify-content: space-around;
  }
}

.col {
  display: flex;
  flex-direction: column;
  padding: 10px;
  &.center {
    align-items: center;
    justify-content: center;
  }
  &.no-padding {
    padding: 0;
  }
  &.col-50 {
    width: 50%;
  }
  &.gap {
    row-gap: 1em;
  }
}

.align-right {
  margin-left: auto;
  margin-right: 0;
}

.card {
  background-color: $light-gray;
  border-radius: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  &.fit {
    width: fit-content;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background: $light-overlay;
}

.loader {
  max-width: 40px;
  width: 100%;
  max-height: 40px;
  height: 100%;
  position: relative;
}

.lds-ring {
  display: inline-block;
  position: relative;
  max-width: 40px;
  width: 100%;
  max-height: 40px;
  height: 100%;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  border: 4px solid $base-green;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $base-green transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
