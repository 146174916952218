@import './styles';

html {
  overflow: hidden;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
