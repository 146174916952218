@import '../../styles/styles';
$sideNavBg: #1A002D;

$icon-size: 45px;

.sideNav {
  width: 250px;
  max-width: 250px;
  min-width: 250px;
  background-color: $sideNavBg;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  transition: all ease 0.5s;
  box-shadow: $--box-shadow-1;
  @media (max-width: $tablet) {
    max-width: 100%;
  }
  &.collapsed {
    max-width: 60px;
    width: 60px;
    min-width: 60px;
    &.scroll {
      min-width: 80px;
    }
  }
}

.routeItems__container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.collapseButton {
  display: flex;
  align-items: center;
  height: 65px;
  width: 100%;
  color: white;
  margin-bottom: 45px;
  padding: 10px;
  & .ionicon {
    cursor: pointer !important;
  }
}

.routeItem {
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  color: white;
  & a {
    color: white;
    display: flex;
    align-items: center;
    width: 100%;
    &:hover {
      color: $base-green;
    }
  }
  &.active {
    background-color: $base-green;
    cursor: initial;
    & a:hover {
      color: white;
      cursor: initial;
    }
  }
  & .anticon {
    width: 30px;
    height: 30px;
    & .img,
    svg {
      width: 30px;
      height: 30px;
    }
  }
}

.routeItem.logout {
  margin-top: auto;
  margin-bottom: 0;
}

.icon__container {
  min-height: $icon-size;
  min-width: $icon-size;
  max-height: $icon-size;
  max-width: $icon-size;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.logo__container {
  width: 100%;
  height: 43px;
  & img {
    height: 40px;
  }
}

.route__label {
  margin-left: 10px;
  display: flex;
  align-items: center;
  width: 100%;
}

.valientaLogo__container {
  height: 100px;
}

.openClose__button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: 0;
  & .anticon {
    display: flex !important;
    align-items: center !important;
  }
  & svg {
    width: 15px !important;
    height: 15px !important;
  }
}

.menuGroup {
  background: #1A002D;
  // padding-left: 10px;
  display: flex;
  flex-direction: column;
  height: 0;
  overflow: hidden;
  &.open {
    height: fit-content;
  }
}
