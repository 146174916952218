@import '../../../styles/styles';

.product__card {
  min-width: 150px;
  max-width: 150px;
  height: 250px;
  border: 1px solid $light-gray;
  padding: 0 0 5px 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin: 0 auto;
  &:hover {
    box-shadow: $--box-shadow-3;
  }
  @media (max-width: $mobile-l) {
    min-width: 100px;
    max-width: 100px;
  }
}

.product__body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image__container {
  position: relative;
  width: 150px;
  margin-bottom: 10px;
  & .product__reference {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 13px;
    background-color: rgba(0, 0, 0, 0.397);
    color: white;
    padding: 0 5px;
    border-bottom-left-radius: 5px;
  }
  @media (max-width: $mobile-l) {
    width: 100px;
  }
}

.product__image {
  height: 170px;
  width: 150px;
  object-fit: cover;
  @media (max-width: $mobile-l) {
    width: 100px;
    height: 150px;
  }
}
.product__action {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1em;
}

.container-upload {
  display: flex;
  justify-content: center;
}

.product__name {
  padding: 0 10px;
  text-align: center;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
