// Responsive screen widths
$mobile-s: 320px;
$mobile-m: 375px;
$mobile-l: 425px;
$tablet: 768px;
$laptop: 1024px;
$laptop-l: 1440px;
$screen-xl: 2560px;

// Blue
$base-blue: #4a5782;
$dark-blue: #23293d;
$medium-blue: #a7b4de;
$light-blue: #f3f2f7;

// purple
$base-purple: #1A002D;
$dark-purple: #1A002D;
$medium-purple: #1a002dde;
$light-purple: #f3f2f7;

// green
$base-green: #06C2AC;
$dark-green: #06C2AC;
$medium-green: #06c2acb6;
$light-green: #fff0f4;


// gray
$base-gray: #484848;
$dark-gray: #222222;
$medium-gray: #b8b8b8;
$light-gray: #f6f6f4;

// Red
$base-red: #d62626;
$dark-red: #b11f1f;
$medium-red: #dc4747;
$light-red: #e16363;

$secondary-button: rgb(230, 230, 230);

// Shadows
$--box-shadow-1: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
$--box-shadow-2: rgba(0, 0, 0, 0.35) 0px 5px 15px;
$--box-shadow-3: rgba(0, 0, 0, 0.24) 0px 3px 8px;

// Linear gradients
// $base-purple-gradient: linear-gradient(315.22deg, #242b41 19.25%, #39487d 73.68%);
$base-purple-gradient: linear-gradient( #1A002D 45%, #06C2AC);

// Overlays
$dark-overlay: rgba(50, 35, 35, 0.425);
$light-overlay: rgba(255, 255, 255, 0.418);
