@import '_variables.scss';

.button {
  height: 45px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  box-shadow: $--box-shadow-1;
  &.large {
    width: 300px;
    @media (max-width: $laptop) {
      width: 200px;
    }
    @media (max-width: $tablet) {
      width: 150px;
      height: 60px;
    }
  }
  &.small {
    width: 150px;
  }
  &.modal {
    width: 100px;
    height: 40px;
  }
  &.disabled {
    cursor: not-allowed;
  }
}

.primary {
  background-color: $base-green;
  color: white;
  &.center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:hover {
    background-color: $dark-green;
    filter: brightness(90%);
  }
  &.disabled {
    background-color: $base-green;
    filter: brightness(85%);
    &:hover {
      background-color: $medium-green;
    }
  }
  &.delete {
    background-color: $base-red;
    &:hover {
      background-color: $medium-red;
    }
  }
}

.secondary {
  background-color: $secondary-button;
  &.center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:hover {
    background-color: $medium-gray;
  }
  &.disabled {
    &:hover {
      background-color: $secondary-button;
    }
  }
}

.linear-gradient-button {
  background: $base-purple-gradient;
  color: white;
  &:hover {
    background: $medium-purple;
  }
  &.disabled {
    background-color: $medium-purple;
    &:hover {
      background-color: $medium-purple;
    }
  }
}

.linear-button {
  background: $base-purple;
  color: white;
  &:hover {
    background: $medium-purple;
  }
  &.disabled {
    background-color: $medium-purple;
    &:hover {
      background-color: $medium-purple;
    }
  }
}

.link-button {
  background-color: transparent;
  font-weight: 500;
  text-decoration: underline;
  box-shadow: none;
  border: none;
  cursor: pointer;
  &:hover {
    color: $medium-gray;
  }
  &.warning {
    color: $base-red;
    &:hover {
      color: $light-red;
    }
  }
}

.button-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-icon-card {
  border: 'none';
  background-color: 'transparent';
  cursor: 'pointer';
  margin: '0.2rem';
}

.link-button {
  background: transparent;
  text-decoration: underline;
  box-shadow: none;
  width: fit-content;
  border: 0;
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
}
