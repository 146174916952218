@import '../../styles/styles';

.login__root {
  max-height: 100vh;
  max-height: --webkit-fill-available;
  height: 100%;
  min-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.password__input {
  position: relative;
  max-width: 335px;
  width: 100%;
  & .show__icon {
    position: absolute !important;
    right: 10px;
    top: 50%;
    height: 20px;
    width: 20px;
    margin-top: -10px;
    &:hover {
      cursor: pointer;
    }
  }
}

.login__grid {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  width: 100%;
  flex-grow: 1;
}

.login__column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  &.image__container {
    background: $base-purple-gradient;
    width: 100%;
    & img {
      object-fit: cover;
      width: 100%;
      max-width: 400px;
      height: auto;
    }
  }
}

.login__label {
  font-family: 'Lato', sans-serif;
  text-align: left;
  margin-bottom: 10px;
  color: $base-purple;
  font-size: 1.2em;
}

.background__column {
  background: $base-purple;
}
