@import '../../../styles/styles';

.antSelect {
  & .ant-select-selector {
    border-radius: 10px !important;
    height: 40px !important;
    border: 1px solid $medium-gray !important;
  }
}

.inputLabel {
  font-size: 14px;
}
